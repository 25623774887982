<template>
  <div 
    v-if="modelValue"
    ref="productItemOverlay"
    class="product-card__overlay"
    @click.stop="closeOverlay({ clickOverlay: true })"
  >
    <div 
      v-if="close"
      class="overlay__close-icon"
    >
      <i 
        class="suiiconfont sui_icon_nav_close_24px"
        :style="{ 
          'top': config?.showCategoryViewStyle ? 0 : '0.2rem',
          'right': config?.showCategoryViewStyle ? 0 : '0.2rem'
        }"
      >
      </i>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { clickCloseOverlay } from '../../analysis'
import { addEventInstance } from '../../js/event'
export default defineComponent({
  name: 'ProductItemOverlay',
  emits: ['update:model-value', 'click'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: true
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
  },
  mounted () {
    if (this.close) addEventInstance.add('touchstart', this, this.onTouchStart)
  },
  methods: {
    onTouchStart (e) {
      const containsTarget = this.$refs.productItemOverlay?.contains(e.target)
      // wishlist-more是针对收藏页自定义more icon用的，解决点击more按钮无法关闭问题
      const isMoreBtn = e.target.classList[1] == 'sui_icon_more_20px' || e.target.classList?.contains('wish-product-more-btn')
      !containsTarget && !isMoreBtn && this.$emit('update:model-value', false)
    },
    closeOverlay ({ clickOverlay } = {}) {
      this.$emit('click')
      if (!this.close) return
      
      this.$emit('update:model-value', false)
      
      clickOverlay && clickCloseOverlay({ isSoldOut: this.isSoldOut })
    }
  },
})
</script>

<style lang="less">
.product-card {
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: @zindex-out;
  }
}
.overlay {
  &__close-icon {
    i.sui_icon_nav_close_24px.suiiconfont { /* stylelint-disable-line */
      .font-dpr(24px);
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      color: #fff;
      opacity: 0.7;
      text-align: center;
    }
  }
}
</style>

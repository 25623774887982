import { debounce, getQueryString } from '@shein/common-function'
import { default as sa, AB_TEST } from './sa'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import { getHistoryData } from '../utils/searchHistoryLocalStorage.js'
import { buriedPointKeyMapping, preSearchPageFromBuriedPointKeys } from '../utils/preSearchBuriedPointConstants'
import isObject from 'lodash/isObject'
/**
 * 获取预搜索页pageFrom,
 * 先从映射表中取,如果不在映射表,就从预搜索页的埋点key中取,其余情况统一按照pageOthers处理
 *
 * @param {string} pageFrom
 * @returns {string}
 */
const getPageFrom = pageFrom => buriedPointKeyMapping.get(pageFrom)
  ?? (preSearchPageFromBuriedPointKeys.includes(pageFrom)
    ? pageFrom
    : 'PageOthers')

export default {
  sa,
  parent_page: '',
  setPageInfo({ pageType = 'all', pageFrom = 'PageOthers', store_code, tsp_code }) {
    const commonSaPageInfo = {
      start_time: Date.now(),
      page_param: {
        abtest: AB_TEST()
      }
    }
    if (pageType === 'store') {
      PageGroup = '店铺搜索中间页'
      SaPageInfo = Object.assign(commonSaPageInfo, {
        page_id: 2982,
        page_name: 'page_store_pre_search'
      })
      SaPageInfo.page_param.store_code = store_code ?? ''
    } else if (pageType === 'brand') {
      SaPageInfo = Object.assign(commonSaPageInfo, {
        page_id: 2982,
        page_name: 'page_chanel_pre_search'
      })
      SaPageInfo.page_param.tsp_code = tsp_code ?? ''
    } else if(pageType === 'PageVisual') { 
      // 商详返回到图搜列表的时候，会修改type为PageVisual
      SaPageInfo = Object.assign(commonSaPageInfo, window.SaPageInfo)
    } else {
      //！！！！！！！！！ 在这里处理给page_pre_search的entrancetype，和 result_content
      // 从上一来源页面取
      let entrancetype = SaPageInfo?.page_param?.entrancetype
      let result_content = SaPageInfo?.page_param?.result_content
      PageGroup = '搜索页'
      SaPageInfo = Object.assign(commonSaPageInfo, {
        page_id: 208,
        page_name: 'page_pre_search'
      })
      // page_name为 'page_pre_search'的埋点须加上页面来源
      SaPageInfo.page_param.pagefrom = getPageFrom(pageFrom)
      // 增加entrancetype字段
      SaPageInfo.page_param.entrancetype = entrancetype || '-'
      SaPageInfo.page_param.result_content = result_content || '-'
      // 趋势搜索与全站搜索共用一个page name
      if (pageType === 'pageTrend') {
        const entry_from = getQueryString({ key: 'entry_from' })
        const pagefrom = getQueryString({ key: 'pagefrom' })
        SaPageInfo.page_param.entry_from = entry_from
        SaPageInfo.page_param.pagefrom = pagefrom === 'page_trend_landing' ? 'PageTrendlanding' : 'PageTrend'
      }
    }

    this.parent_page = SaPageInfo?.page_param?.pagefrom || getQueryString({ key: 'pagefrom' }) || SaPageInfo?.page_name
    this.result_count = SaPageInfo.page_param.result_count

    requestIdleCallback(() => {
      window.sa('set', 'setPageData', SaPageInfo)
    })
  },
  setGlobalTrackInfo({ full_name, result_type, word_type, search_redir }) {
    if (full_name && result_type) {
      searchWordsGlobalAnalysis.set({
        result_type,
        word_type,
        result_word: full_name,
        d: search_redir,
        parent_page: this.parent_page,
        result_count: this.result_count,
        entrancetype: SaPageInfo?.page_param?.entrancetype || '-'
      })
    }
    return { ici: searchWordsGlobalAnalysis.getPageFrom() }
  },
  exposeSearch: debounce({
    func: function (payload) {  // todo: 需确认是否是箭头函数 this指向
      this.sa.exposeSearch(payload)
    },
    wait: 1000
  }),
  clickSearch(payload) {
    this.sa.clickSearch(payload)
  },
  exposeBrandSearch: debounce({
    func: function (payload) {  // todo: 需确认是否是箭头函数 this指向
      this.sa.exposeBrandSearch(payload)
    },
    wait: 1000
  }),
  exposeStoreSearch: debounce({
    func: function (payload) {  // todo: 需确认是否是箭头函数 this指向
      this.sa.exposeStoreSearch(payload)
    },
    wait: 1000
  }),
  clickStoreSearch(payload) {
    this.sa.clickStoreSearch(payload)
  },
  clickStoreSearchIcon(payload){
    this.sa.clickStoreSearchIcon(payload)
  },
  clickBrandSearch(payload) {
    this.sa.clickBrandSearch(payload)
  },
  doFirstViewAnalysis(vm) {
    const options = {
      historyWords: vm.historyWords,
      hotImages: vm.hotImages,
      crowdId: [
        ...new Set(
          [
            ...vm.defaultWords,
            ...vm.hotImages 
          ]
            .map(_ => _.crowdId)
            .filter(_ => +_)
        )
      ].join(),
    }
    if (vm.pageType === 'store') {
      options.src_module = 'store_search'
      options.search_content = ''


      const src_identifier = getQueryString({ key: 'src_identifier' })
      if (src_identifier) {
        options.src_identifier = src_identifier
        options.search_content = ''
      } else {
        const key = vm.$route.query.store_code
        const historyData = getHistoryData(vm.pageType, key)

        options.src_identifier = historyData ? historyData.map((item, index) => {
          return `st=${5}\`sc=${isObject(item) ? item.word : item}\`sr=0\`ps=${index + 1}`
        }).join(',') : ''
      }
    } else if (vm.pageType === 'brand') {
      options.src_module = 'chanel_search'
      options.search_content = ''

      const src_identifier = getQueryString({ key: 'src_identifier' })
      if (src_identifier) {
        options.src_identifier = src_identifier
        options.search_content = ''
      } else {
        const key = vm.$route.query.intent_channel_id
        const historyData = getHistoryData(vm.pageType, key)

        options.src_identifier = historyData ? historyData.map((item, index) => {
          return `st=${5}\`sc=${isObject(item) ? item.word : item}\`sr=0\`ps=${index + 1}`
        }).join(',') : ''
      }
    } else if (vm.pageType === 'pageTrend') {
      options.src_module = 'trend_search'
    }

    // 只针对泛列表主站搜索
    if(vm.pageType !== 'store' && vm.pageType !== 'brand') {
      let args = {
        entrancetype: SaPageInfo?.page_param?.entrancetype || '-',
      }
      this.sa.searchInput('expose_top_site_search', {
        ...options,
        associationWords: vm.associationWords,
      }, args)
      return
    }
    this.sa.searchInput('expose_top_site_search', {
      ...options,
      associationWords: vm.associationWords,
    }, {})
    
  },
  exposeAssoc(vm) {
    const srcModuleMap = {
      all: 'search',
      store: 'store_search',
      brand: 'chanel_search',
      pageTrend: 'trend_search'
    }
    // 参见wiki：pageId=1262278302
    let associationWordsLen = (vm?.associationWords?.word?.length ?? 0)
    let currentWordLen = vm.currentWord.keywords?.length || 0
    // sa
    this.sa.searchInput('expose_top_site_search', {
      currentWord: vm.currentWord.keywords,
      correction: vm.correction,
      associationWords: vm.associationWords,
      src_module: srcModuleMap[vm.pageType] ?? 'search',
    }, {
      // 输入内容为空时不上报, 输入内容超过50字符时不上报, 无联想词数据也不给上报. 其它情况直接返回联想词结果个数
      suggest_result_count: (currentWordLen === 0 || currentWordLen >= 50) ? 0 : associationWordsLen,
      entrancetype: SaPageInfo?.page_param?.entrancetype || '-'
    })
  },
  search({ keyword, searchParamsRecord, full_name, result_type, search_redir, data, crowdId, originWord, pageType = 'all', trend_list_content, isClick = true, wordTagType, wordExtraReport, reportExtendData, actionId }) {
    if (isClick) {
      requestIdleCallback(() => {
        this.sa.doSearch({ keyword, searchParamsRecord, result_type, data, crowdId, originWord, pageType, trend_list_content, wordTagType, wordExtraReport, reportExtendData, actionId })
      })
    }
    return this.setGlobalTrackInfo({ full_name, result_type, search_redir, word_type: data?.type || '' })
  }
}

<script lang="jsx">
import { defineComponent, defineAsyncComponent } from 'vue'
import { transformImg } from '@shein/common-function'
const ImgCheckbox = defineAsyncComponent(() => import('./ImgCheckbox.vue'))

export default defineComponent({
  name: 'ProductCardImgRightTop',
  emits: ['focusCheckbox'],
  props: {
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    selectStatus: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    selectPosition: {
      type: String,
      default: ''
    },
    needShowOldLabel: {
      type: Boolean,
      default: false,
    },
    promoLabelSrc: {
      type: String,
      default: ''
    },
  },
  render({ $props: props, $attrs: listeners }) {
    // 视频icon
    const showVideoIcon = props.config.showVideoIcon && props.curData?.video_url
    // 品牌
    const brandLogo = props.pretreatInfo?.brandLogo || null
    const brandLogoName = props.pretreatInfo?.brandLogoName
    const showBrandLogo = brandLogo && props.config.showSeriesBrand && props.needShowOldLabel
    const showPromoLabel = props.promoLabelSrc && props.needShowOldLabel
    const showImgTopRight = showVideoIcon || showBrandLogo || props.showSelect || showPromoLabel

    const handleFocus = () => listeners?.['onFocus']?.()
    const handleBlur = () => listeners?.['onBlur']?.()

    const checkedBoxStyle = props.config.style?.checkedBoxStyle ?? ''


    let iconEl = null
    if (showPromoLabel) {
      // 大促标签
      iconEl = (
        <img
          class="product-card__promo-label lazyload"
          src={props.constantData.LAZY_IMG_PIXEL}
          data-src={transformImg({ img: props.promoLabelSrc })}
          alt=''
        />
      )
    } else if (showVideoIcon) {
      // 视频 icon
      iconEl = (
        <div class="product-card__video-tag">
          <i class="iconfont icon-play1"></i>
        </div>
      )
    } else if (showBrandLogo) {
      // 品牌
      iconEl = (
        <img
          class="product-card__brand lazyload"
          src={props.constantData.LAZY_IMG_PIXEL}
          data-src={transformImg({ img: brandLogo })}
          alt=''
          tabindex="0"
          aria-label={brandLogoName}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )
    }

    if (showImgTopRight) {
      return (
        <div class="product-card__img-topright">
          {iconEl}
          {/* 选择模式 */}
          {props.showSelect && props.selectPosition === 'imgRight' 
            ? (
              <ImgCheckbox 
                selectStatus={props.selectStatus}
                checkedBoxStyle={checkedBoxStyle}
                onFocusCheckbox={() => this.$emit('focusCheckbox')}
              />
            )
            : null}
        </div>
      )
    } else {
      return null
    }
  },
})
</script>

<style lang="less">
.product-card__img-topright {
  .product-card {
    &__video-tag {
      position: absolute;
      top: 0.2133rem;
      right: .2rem
        /*rtl:ignore*/
      ;
      width: 0.48rem;
      height: 0.44rem;
      background: #FFFFFF;
      border-radius: 0.1067rem;
      color: #ff696e;
      .flexbox();
      .align-center();
      .pack-center();
    }

    &__brand,
    &__promo-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto !important; /* stylelint-disable-line declaration-no-important */
    }

    &__select {
      &.sui-checkbox {
        position: absolute;
        top: 0.16rem;
        right: 0.16rem;
        z-index: @zindex-select;
      }
    }
  }
}</style>

<script lang="jsx">
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
export default defineComponent({
  name: 'ProductCardPropertyUpsell',
  props: {
    getCornerPropertyUpsell: {
      type: Object,
      default: null
    },
    hasShapedBelt: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props, $data: data }) {
    const { getCornerPropertyUpsell = {}, hasShapedBelt } = props
    const { type, icon, textList } = getCornerPropertyUpsell

    return (
      <div
        class={[
          'product-card__property-upsell',
          data.class,
          data.staticClass,
        ]}
        style={[
          data.style,
          data.staticStyle
        ]}
      >
        {
          type === 'text' ? (<div class='property-upsell__text' style={{ '--need-margin-bottom': hasShapedBelt ? '0.426rem' : '4px' }}>
            {textList[0]?.labelLang || ''}
          </div>) : null
        }
        {
          type === 'img' || type === 'image' ? (<div>
            <img
              src={transformImg({ img: icon })}
              width={'100%'}
              height={'auto'}
              alt='image for product card property upsell'
            />
          </div>) : null
        }
      </div>
    )
  },
})
</script>

<style lang="less">
.product-card__property-upsell {
  isolation: isolate;
  display: flex;

  .property-upsell__text {
    color: white;
    border: 0.5px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.60);
    padding: 2px 8px;
    margin-right: 4px /* rtl: ignore */;
    margin-bottom: var(--need-margin-bottom, 4px); // fixed 避免黑五的腰带异形部分会遮挡
    border-radius: 5000px;
    font-weight: 400;
    font-size: var(--property-upsell-text-size, 11px); // 一行一图下的属性卖点字体可能需要调整
    line-height: normal;
  }
}
</style>

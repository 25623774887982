<script lang="jsx">
import { defineComponent, withModifiers } from 'vue'
export default defineComponent({
  name: 'ProductItemAddToBag',
  props: {
    size: {
      type: String,
      default: 'middle',
    },
    isRw: {
      type: Boolean,
      default: false,
    },
    noIconBackground: {
      type: Boolean,
      default: false,
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  render({ $props: props, $data: data, $attrs: listeners }) {
    const size = props.size || 'middle'
    const externalClass = data?.class || ''
    // 是否romwe
    let isRw = Boolean(props.isRw)
    // 加车icon没背景
    const noIconBackground = Boolean(props.noIconBackground)
    // 加车icon有阴影
    const boxShadow = Boolean(props.boxShadow)
    // 外部传入的style
    const customStyle = props.customStyle || {}

    const iconBtn = (
      <i
        class={[
          'suiiconfont',
          'j-icon-addtobag',
          `product-item__add-bag_${size}`,
          {
            'sui_icon_addtobag_ab_20px': isRw,
            'sui_icon_addtocart_20px': !isRw,
            'product-item__add-bag_shadow': boxShadow,
            'product-item__add-bag_no-background': noIconBackground
          },
        ]}
        style={{ backgroundColor: props.backgroundColor }}
      />
    )

    return (
      <div
        class={[
          'product-item__add-bag_btn-wrap',
          externalClass
        ]}
        style={customStyle}
        onClick={withModifiers(listeners['onOpenQuickAdd'], ['stop'])}
      >
        {
          iconBtn
        }
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item {
  &__add-bag {
    &_small {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      box-sizing: content-box;
      right: -10px;
      bottom: -10px;
      position: relative;
      // border: 10px solid transparent;
      margin: 10px;
      background-clip: content-box;
      background-color: rgba(255, 255, 255, .8);
      border-radius: 50%;
      text-align: center;
      z-index: 5; /* stylelint-disable-line declaration-property-value-blacklist */
    }
    &_middle {
      display: inline-block;
      width: 0.96rem;
      height: 0.64rem;
      line-height: 0.64rem;
      border-radius: 0.88rem;
      text-align: center;
    }
    &_big {
      background: #f6f6f6;
      width: 0.7467rem;
      height: 0.7467rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &_no-background {
      background: none;
      height: 0.64rem;
    }
    &_shadow {
      box-shadow: 0px 0px 4 / 37.5rem rgba(0, 0, 0, 0.25);
    }
    &_btn-wrap {
      // height: 0.64rem;
      align-self: flex-start;
      line-height: 0.64rem;
    }
  }
}
</style>

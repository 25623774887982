
<script lang="jsx">
import { defineComponent } from 'vue'
import ProductCardServiceTag from './ProductCardServiceTag.vue'
import SearchFilterLabel from './SearchFilterLabel.vue'

export default defineComponent({
  name: 'ProductCardLabelsContainer',
  components: { ProductCardServiceTag, SearchFilterLabel },
  inject: ['item', 'config'],
  render({ $slots: slots, $attrs: listeners }) {
    const { pretreatInfo } = this?.item ?? {}
    const { hideServiceLabels, showSearchFilters } = this?.config ?? {}
    const hasSearchFilters = showSearchFilters && pretreatInfo?.searchFilters?.length > 0

    const handleClickSearchFilter = (e) => {
      listeners?.['onClickSearchFilter']?.(e)
    }

    return (
      <div class="product-card__label-container">
        { !hideServiceLabels ? 
          <ProductCardServiceTag>
            { slots.goodsServiceTag?.() }
          </ProductCardServiceTag> 
          : null 
        }
        { hasSearchFilters ? <SearchFilterLabel onClickSearchFilter={ handleClickSearchFilter } /> : null }
      </div>
    )
  },
})
</script>

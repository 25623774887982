<script lang="jsx">
import { defineComponent, withModifiers } from 'vue'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
function handleClickLink(store_code, goodsId, catId) {
  if (window) {
    const { langPath } = gbCommonInfo
    location.href = `${langPath}/store/home?store_code=${store_code}&main_goods_id=${goodsId}&main_cate_id=${catId}&rule_poskey=ListShopItemList`
  }
}
export default defineComponent({
  name: 'ProductCardStoreLink',
  inject: ['config', 'item'],
  directives: {
    expose,
    tap,
  },
  props: {
    storeInfo: {
      type: Object,
      default: () => ({}),
    },
    goodsId: {
      type: String,
      default: '',
    },
    catId: {
      type: String,
      default: '',
    },
  },
  render({ $props: props }) {
    const { storeInfo } = props
    return (
      <div
        v-expose={{
          id: '2-3-34',
          data: { storeCode: storeInfo?.storeCode, goodsId: this.item?.goods_id },
        }}
        v-tap={{
          id: '2-3-35',
          data: { storeCode: storeInfo?.storeCode, goodsId: this.item?.goods_id },
        }}
        class="product-card__store-link"
        onClick={withModifiers(() => handleClickLink(storeInfo.storeCode, props.goodsId, props.catId), ['stop'])}
      >
        <div class="product-card__store-link_title">{storeInfo.title}</div>
        <i class="suiiconfont sui_icon_more_right_12px product-card__store-link_icon"></i>
      </div>
    )
  },
})
</script>

<style lang="less">
.product-card {
  &__store-link {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    height: 0.6933rem;
    margin-top: 0.16rem;
    justify-content: space-between;
    align-items: center;
    border-top: 0.0133rem solid #e5e5e5;
    &_title {
      font-size: 0.2667rem;
      color: #767676;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_icon {
      padding: 0 0 0 0.1067rem;
      font-size: 0.2667rem;
      color: #959595;
    }
  }
}
</style>

<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductCardPriceVipPrice',
  props: {
    amountWithSymbol: {
      type: String,
      default: '',
    },
    isRw: {
      type: Boolean,
      default: false,
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
  },
  render({ $props: props }) {
    if (props.isRw) {
      return (
        <div
          class="rw-vip-price"
          tabIndex="0"
          role="none"
          aria-label={props.amountWithSymbol}
        >
          <div class="rw-vip-price__container">
            <span class="price">{props.amountWithSymbol}</span>
            <span class="icon"></span>
          </div>
        </div>
      )
    } else {
      return (
        <div
          class="shein-vip-price"
          tabIndex="0"
          role="none"
          aria-label={props.amountWithSymbol}
        >
          <span>{props.amountWithSymbol}</span>
          <em
            class={['vip-exclusive-tag_color-black', props.cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag']}></em>
        </div>
      )
    }
  },
})
</script>

<style lang="less">
@rw_exclusive_price: '/pwa_dist/rw_images/rw_vip_price_icon-9140c93afa.png';

.shein-vip-price {
  margin-top: 0;
  .font-dpr(24px);
  color: #a78a45;
  line-height: 0.3733rem;
}
.rw-vip-price {
  &__container {
    display: inline-flex;
    max-width: 100%;
    height: var(--swiper-container-height, 0.42rem);
    font-size: 0;
    font-weight: bold;
    color: #936D0C;
    line-height: var(--swiper-container-height, 0.42rem);
    background-color: #FFE995;
    border-radius: 0.1867rem;
    padding-left: 0.133rem;
    padding-top: 0;
    >span {
      vertical-align: top;
      font-size: 0.32rem;
      line-height: .4667rem;
    }
    .price {
      .text-overflow();
    }
    .icon {
      display: inline-block;
      height: 100%;
      width: 0.827rem;
      background-color: #333;
      margin-left: 0.12rem;
      border-radius: 0 0.1867rem 0.1867rem 0;
      background-size: cover;
      background-image: url(@rw_exclusive_price);
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="less">
@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';

.vip-exclusive-tag,
.vip-exclusive-tag__ar {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 3.23rem .77rem;
  width: .85rem;
  height: .27rem;
  &_color-black {
    background-position: -.48rem -.25rem;
  }
}
.vip-exclusive-tag {
  background-image: url(@s3_icon);
}
.vip-exclusive-tag__ar {
  background-image: url(@s3_icon_ar);
  vertical-align: -0.026667rem;
}
</style>

<template>
  <div
    ref="currContainer"
    class="visitor-label-container"
  >
    <div
      v-show="isAniStart"
      :class="labelCls"
      class="visitor-label-content"  
    >
      <sui_icon_hot_12px
        color="#fff"
        size="12px"
        role="button"
        tabindex="0"
      />
      {{ text }}
    </div>
  </div>
</template>
  
<script>
/**
 * @file 浏览人数标签-弹幕组件
 */

import { defineComponent, onMounted, ref, onUnmounted, computed } from 'vue'
import { sui_icon_hot_12px  } from '@shein-aidc/icon-vue3'
export default defineComponent({
  name: 'VisitorLabel',
  components: {
    sui_icon_hot_12px,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  setup () {
    const currContainer = ref(null)
    const { isAniStart, labelCls } = useLabelShow(currContainer)

    return {
      isAniStart,
      labelCls,
      currContainer,
    }
  },
})

const useLabelShow = (currContainer) => {
  const isAniStart = ref(false)
  const showLabel = ref(false)
  const labelCls = computed(() => ({
    'visitor-label-in': showLabel.value,
    'visitor-label-out': !showLabel.value,
  }))
  let flag1 = null
  let flag2 = null

  onMounted(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(entries => {
        if (entries[0]?.isIntersecting) {
          if (showLabel.value) {
            return
          }
          flag1 = setTimeout(() => {
            isAniStart.value = true
            showLabel.value = true
            flag2 = setTimeout(() => {
              showLabel.value = false
            }, 4000)
          }, 1000)
        } else {
          isAniStart.value = false
          showLabel.value = false
          flag1 && clearTimeout(flag1)
          flag2 && clearTimeout(flag2)
        }
      })
      // 开始观察
      observer.observe(currContainer.value)
    }
  })

  onUnmounted(() => {
    flag1 && clearTimeout(flag1)
    flag2 && clearTimeout(flag2)
  })

  return {
    isAniStart,
    labelCls,
  }
}
</script>
  
<style lang="less" scoped>
  .visitor-label-container {
    margin-left: 4px;
    margin-top: 6px;
    max-width: 100%;
  }

  .visitor-label-content {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.60);
    padding: 1px 8px 1px 4px;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .visitor-label-in {
    animation: visitor-label-in-kf 0.3s cubic-bezier(0, 0, 0.5, 1) both;
  }
  .visitor-label-out {
    animation: visitor-label-out-kf 0.3s cubic-bezier(0.5, 0, 1, 1) both;
  }

  @keyframes visitor-label-in-kf {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes visitor-label-out-kf {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
      transform: translateY(-5px);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
</style> 

<template>
  <div>
    <!-- 负反馈，禁止浏览器长按图片菜单蒙层 -->
    <div 
      class="product-card__feed-hack"
      :class="{oneline: isOneLine}"
      @click="$emit('feedHackClick')"
    ></div>
    <ProdcutItemFeedbackContent 
      v-if="modelValue"
      :feedback="feedback"
      :cur-data="curData"
      :language="language"
      :config="config"
      :is-sold-out="isSoldOut"
      :scroll-timers="scrollTimers"
      :img-ratio="imgRatio"
      @close="close"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import ProdcutItemFeedbackContent from './ProdcutItemFeedbackContent'
export default defineComponent({
  components: {
    ProdcutItemFeedbackContent
  },
  emits: ['update:model-value', 'hideSelf', 'feedHackClick'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    feedback: {
      type: Object,
      default: () => ({})
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    curOperateIndex: {
      type: Number,
      default: -1
    },
    index: {
      type: Number,
      default: -1
    },
    config: {
      type: Object,
      default: () => ({})
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    scrollTimers: {
      type: Number,
      default: 0
    },
    imgRatio: {
      type: String,
      default: ''
    }
  },
  computed: {
    isOneLine () {
      return this.feedback?.sceneKey == 'ONE_IN_A_ROW'
    }
  },
  watch: {
    curOperateIndex (newVal) {
      if (newVal != this.index) {
        this.close(false)
      }
    }
  },
  methods: {
    close (hide) {
      this.$emit('update:model-value', false)
      if (hide) {
        this.$emit('hideSelf')
      }
    }
  },
})
</script>
<style lang="less">
.product-card{
  &__feed-hack {
    position:absolute;
    top:0;
    left:0/*rtl:ignore*/;
    right:0/*rtl:ignore*/;
    bottom:0;
    opacity:0;
    &.oneline {
      width: 2.56rem;
      height: 3.41rem;
      right: auto/*rtl:ignore*/;
    }
  }
}
</style>

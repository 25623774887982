import { daEventCenter } from 'public/src/services/eventCenter'

// 商品项点击埋点（配置了delayClickAnalysis时触发）
const clickProductItem = ({ itemDAEventClickId, target }) => {
  daEventCenter.triggerNotice({
    daId: itemDAEventClickId,
    target
  })
}

// 点击find similar按钮
const clickFindSimilarBtn = (extraData) => {
  extraData.isOutOfStock = extraData.isSoldOut
  extraData.findsimilar_method = extraData.isSoldOut ? 'SoldOut' : 'ClickMore'
  daEventCenter.triggerNotice({
    daId: '1-7-1-3',
    extraData
  })
}

// 曝光find similar按钮
const exposeFindSimilarBtn = (extraData) => {
  daEventCenter.triggerNotice({
    daId: '2-3-16',
    extraData
  })
}

// 点击delete按钮
const clickDeleteBtn = (extraData) => {
  extraData.item_sold_out = extraData.isSoldOut
  extraData.delete_method = extraData.isSoldOut ? 'SoldOut' : 'ClickMore'
  daEventCenter.triggerNotice({
    daId: '1-10-4-9',
    extraData
  })
}

// 点击加车按钮
const clickAddBagAna = ({ target, extraData }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-5',
    target,
    extraData
  })
}

// 关闭overlay
const clickCloseOverlay = ({ isSoldOut }) => {
  daEventCenter.triggerNotice({
    daId: '1-10-4-7',
    extraData: {
      item_sold_out: isSoldOut
    }
  })
}

// 曝光负反馈弹窗
const exposeFeedback = ({ goods_id, feedback, feedbackType, goodsLabel }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-9',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      feedback_value: feedbackType.join('|'),
      goods_label: goodsLabel || ''
    }
  })
}

// 点击负反馈选项
const clickFeedback = ({ goods_id, feedback, index, id, goodsLabel }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-10',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      feedback_value: index + 1 + '_' + id,
      goods_label: goodsLabel || ''
    }
  })
}

// 负反馈点击找相似
const clickFindSimilarOnFeedback = ({ goods_id, feedback, abtest }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-11',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      similar_from: 'expand',
      abtest
    }
  })
}

// 点击负反馈选项，曝光找相似
const exposeFindSimilarByFeeback = ({ goods_id, feedback, abtest }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-12',
    extraData: {
      goods_id,
      entry_from: feedback.pageStyleKey,
      similar_from: 'expand',
      abtest
    }
  })
}

// 点击分布式筛选
const clickSearchFilterAna = ({ id, itemIndex, filterIndex, dataSource }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-17',
    extraData: {
      id,
      itemIndex,
      filterIndex,
      dataSource
    }
  })
}

// 曝光分布式筛选
const exposeSearchFilterAna = (searchFilter, target) => {
  daEventCenter.triggerNotice({
    daId: '2-3-18',
    extraData: {
      searchFilter,
      target
    }
  })
}

const exposeHorseRaceLabel = ({ targets, goods_id, rankingListAna }) => {
  const prd_info = goods_id + ''
  
  const label_info = targets?.map(target => {
    const type = target && target.getAttribute('data-type') || ''
    const realType = target && target.getAttribute('data-real-type') || '' 
    const tagId = target && target.getAttribute('data-tag-id') || '-'
    const tagIndex = target && target.getAttribute('data-tag-index') || ''
    const tagContent = target && target.getAttribute('data-tag-content') || ''
    const appTraceInfo = target && target.getAttribute('data-tag-appTraceInfo') || ''
    
    if (type === 'ranking') {
      return '-'
    }
    if (['billion', 'userVoucher'].includes(realType)) {
      return ''
    }

    if (appTraceInfo) {
      return appTraceInfo
    } else {
      // 标签id`标签展示坑位数`标签文案
      return `${tagId}\`${tagIndex}\`${tagContent.replace(/\s/g, '')}`
    }
  }).filter(v => v).join(',')

  daEventCenter.triggerNotice({
    daId: '2-3-42',
    extraData: {
      prd_info,
      label_info,
      ranking_list_module: rankingListAna ? 'ranking_list_label' : '-',
      ranking_list_identifier: rankingListAna || '-'
    }
  })
}

export {
  clickProductItem,
  clickFindSimilarBtn,
  exposeFindSimilarBtn,
  clickDeleteBtn,
  clickAddBagAna,
  clickCloseOverlay,
  exposeFeedback,
  clickFeedback,
  clickFindSimilarOnFeedback,
  exposeFindSimilarByFeeback,
  clickSearchFilterAna,
  exposeSearchFilterAna,
  exposeHorseRaceLabel
}

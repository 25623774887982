<script lang="jsx">
import { defineComponent } from 'vue'
import { views } from '../../js/constant.js'
import { hitHistoricalPrice, exportgetByteLength } from '../../js/utils.js'

export default defineComponent({
  name: 'DiscountLabelV2',
  inject: {
    constantData: {
      default: ()=>({})
    },
    labelsFromKey: {
      default: ''
    },
    goodsInfo: {
      default: ()=>({})
    },
    language: {
      default: ()=>({})
    },
    config: {
      default: ()=>({})
    },
    finalPriceAndDiscountInfo: {
      default: ()=>({})
    },
    setAttrForAnalysis: {
      default: () => {
        return ()=>{}
      }
    }
  },
  props: {
    position: {
      type: String,
      default: 'price'
    }
  },
  render({ $slots: slots, $data: data }) {
    const { labelsFromKey, constantData, config, goodsInfo, finalPriceAndDiscountInfo, language, setAttrForAnalysis } = this || {}

    const { style, showDiscountLabelCountdownStyle, showPriceDiscountDescInDiscountLabel, moduleFrom } = config

    const { color, finalPriceType, finalDiscount, priceStateCollection } = finalPriceAndDiscountInfo || {}

    const { estimatedPriceInfo, salePrice, retailPrice } = goodsInfo || {}

    const cssRight = constantData?.GB_cssRight

    let unitDiscountString = finalDiscount.unitDiscountString || ''

    if (!unitDiscountString) return null
    
    // 反转
    if (cssRight) {
      unitDiscountString = unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
    }

    // buybox场景下，不展示普通折扣标签，只展示倒计时折扣标签
    const onlyShowPrefixDiscountLabel = ['buybox'].includes(moduleFrom)
    if (onlyShowPrefixDiscountLabel) {
      const { estimatedPriceInfo } = goodsInfo || {}

      if (!estimatedPriceInfo?.endTime || !showDiscountLabelCountdownStyle) {
        return null
      }
    }

    const historicalPrice = goodsInfo[labelsFromKey]?.historicalPrice

    const historicalPriceState = historicalPrice?.length ? hitHistoricalPrice({
      salePrice: salePrice,
      retailPrice: retailPrice,
      historicalPrice: historicalPrice,
    }) : { show: false }

    // 前缀
    const prefixText = (() => {
      const lastDayText = language.SHEIN_KEY_PWA_34601 || 'Last day'
      const lastDayTextLength = exportgetByteLength(lastDayText)

      const hasMaxLength = labelsFromKey === views.LIST_MOBILE_VERTICAL_VIEW
      
      if (!(hasMaxLength && lastDayTextLength > 22) && finalPriceType === 'estimatedPrice' && !priceStateCollection?.hasFlashPromotion && estimatedPriceInfo?.endTime && showDiscountLabelCountdownStyle) {
        const lastDayText = language.SHEIN_KEY_PWA_34601 || 'Last day'

        setAttrForAnalysis((el) => {
          el.setAttribute('data-discount-count-down', `list_price_time_tag_TagTitle`)
        })
        return (
          <span 
            class="prefix-text"
          >{ lastDayText }</span>
        )
      }

      if (showPriceDiscountDescInDiscountLabel && historicalPriceState?.show && historicalPriceState?.labelLang) {

        const historicalPriceTextLength = exportgetByteLength(historicalPriceState.labelLang)
        if (hasMaxLength && historicalPriceTextLength > 22) {
          return null
        }

        setAttrForAnalysis((el) => {
          if (historicalPriceState?.appTraceInfo) {
            el.setAttribute('data-historical-price', historicalPriceState?.appTraceInfo)
          } else {
            const { high, low, adjust } = historicalPriceState?.priceData || {}
            const tagIds = [high.tagId, low.tagId, adjust.tagId]
            const labelLangEn = historicalPriceState.labelLangEn || ''
            el.setAttribute('data-historical-price', `show_lowest_price_${tagIds.join('_')}_${labelLangEn}`)
          }
        })
        return (
          <span 
            class="prefix-text"
          >{ historicalPriceState.labelLang }</span>
        )
      }

      return null
    })()

    const discountLabelStyle = style?.discountLabel || ''

    const classNames = [
      'product-card__discount',
      data.class,
      data.staticClass,
      color || 'origin',
      discountLabelStyle
    ]

    if (prefixText && !discountLabelStyle) {
      classNames.push('bg')
    }
    
    return (
      <div
        role="contentinfo"
        class={classNames}
        style={[data.style, data.staticStyle]}
      >
        { slots.default?.() }
        { prefixText }
        <span class="discount-text">{ unitDiscountString }</span>
      </div>
    )
  },
})
</script>

<style lang="less" scoped>
.product-card__discount {
  position: relative;
  direction: ltr /* rtl: ignore */;

  margin-right: 0 /* rtl: 1px */;

  display: flex;
  align-items: center;
  
  padding: 0 .053rem;
  height: .373rem;
  line-height: .373rem;
  border: 0;

  // ar 站 rtl 只针对 ar 语言，一旦 英文与阿拉伯文混排，new 标签会加入英文的方向，导致显示问题
  unicode-bidi: normal /* rtl: plaintext */;

  border-radius: 0.0533rem;

  font-size: .266rem;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1px solid transparent;
    border-radius: .106rem;
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  &.origin {
    color: @sui_color_discount;
    &::after {
      border-color: @sui_color_discount;
    }
    .prefix-text::after {
      background-color: @sui_color_club_rosegold_dark1;
    }
  }
  &.brown {
    color: @sui_color_club_rosegold_dark1;  
    &::after {
      border-color: @sui_color_club_rosegold_dark1;
    }
    .prefix-text::after {
      background-color: @sui_color_club_rosegold_dark1;
    }
  }
  &.darkGold {
    border-color: #806208;
    color: #806208;
    &::after {
      border-color: #806208;
    }
    .prefix-text::after {
      background-color: #806208;
    }
  }

  &.bg {
    // 背景色默认全部使用橙色
    background: @sui_color_discount;
    color: @sui_color_white;

    &::after {
      border-color: rgba(255, 255, 255, 0);
    }
    .prefix-text::after {
      background: rgb(255, 255, 255);
    }
    // &.origin {
    //   background: @sui_color_discount;
    //   color: @sui_color_white;
    //   &.prefix-text::after {
    //     background: @sui_color_discount;
    //   }
    // }
    // &.brown {
    //   background: @sui_color_club_rosegold_dark1;
    //   color: @sui_color_white;
    //   &.prefix-text::after {
    //     background: @sui_color_club_rosegold_dark1;
    //   }
    // }
    // &.darkGold {
    //   background: #806208;
    //   color: @sui_color_white;
    //   &.prefix-text::after {
    //     background: #806208;
    //   }
    // }

  }

  .prefix-text {
    position: relative;
    padding-right: .133rem;

    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      right: .053rem;
      top: 50%;
      transform: translateY(-50%) scale(.5, 1);
      width: 1px;
      height: .213rem;
      background: @sui_color_discount;
      opacity: .5;
    }
  }

  .discount-text {
    flex: 1 0 auto;
  }
}

</style>

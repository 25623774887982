<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SaveOfPriceCut',
  inject: ['language', 'constantData'],
  props: {
    saveAmount: {
      type: String,
      default: ''
    },
  },
  render({ $props: props }) {
    const { language, constantData } = this || {}

    const { saveAmount = '' } = props

    const { PUBLIC_CDN = '', GB_cssRight = false  } = constantData || {}

    /* 收藏页-降价置顶模块-Save降价模块 */
    return (
      <div class="saveofpricecut">
        <span class="saveofpricecut__top-text">{ language?.SHEIN_KEY_PWA_27128 || 'Save' }</span>
        <div class="saveofpricecut__content">
          <img 
            src={`${PUBLIC_CDN}/pwa_dist/images/wishlist/wishlist-save-e8bf3232d7.svg`}
            style={GB_cssRight ? { transform: 'rotateY(180deg)' } : { transform: 'rotateY(0deg)' } }
            alt=''
          />
          <span>{ saveAmount }</span>
        </div>
      </div>
    )
  },
})
</script>

<style lang="less">
// 由外部决定腰带的文字展示
// --belt-text-font-size

.saveofpricecut {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0.77333rem;
    padding: 0.08rem;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    background: linear-gradient(90deg, #C44A01 0%, rgba(196, 74, 1, 0.00) 100%);

    &__top-text {
        color: var(--sui-color-white, #FFF);
        font-family: 'SF UI Text';
        font-size: 0.26667rem;
        font-style: normal;
        line-height: 1;
    }

    &__content {
        width: 100%;
        display: flex;
        margin-top: 0.01rem;
        align-items: center;
        img {
            width: 0.26667rem;
            height: 0.26667rem;
        }
        span {
            width: calc(100% - 0.9rem);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--sui-color-white, #FFF);
            font-family: SF UI Display;
            font-size: 0.29333rem;
            font-weight: 700;
            text-align: left;
        }
    }
}
</style>

<template>
  <div class="price-info_s3vip-icon">
    <i class="sh_m_s3_vip_10px"></i>
  </div>
</template>

<script name="ProductCardS3VipSalePriceIcon" setup>

</script>

<style scoped>
.price-info_s3vip-icon {
  direction: ltr /*rtl:ignore*/;
  
  margin-left: .05rem;

  display: flex;
}

.popover-content {
  display: flex;
  align-items: center;
}
</style>

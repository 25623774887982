// 组件实例
let instance = null
// 触发组件加载的函数
let importComponentFunc = null
// showDrawer 队列
let showDrawerQueue = []
// 首次打开弹窗时，当前的页面信息 (避免弹窗加载过程中，切换了页面)
let firstOpenPageFlag = null

const getPageFlag = () => {
  const { pathname } = window.location
  const { key } = history.state
  return `${pathname}_${key}`
}

// 注册加载组件方法, func()返回一个promise
export const installRankDrawer = (func) => {
  importComponentFunc = func
}

// 组件挂载 (在组件mounted生命周期中调用)
export const instanceMounted = (vm) => {
  instance = vm

  const nowPageFlag = getPageFlag()
  if (firstOpenPageFlag && firstOpenPageFlag !== nowPageFlag) {
    showDrawerQueue.length = 0
    return
  }

  firstOpenPageFlag = null
  if (showDrawerQueue.length) {
    showDrawerQueue.forEach(fn => fn())
    showDrawerQueue.length = 0
  }
}

export default {
  async showDrawer(params, extendParams) {
    if (instance) {
      if (!instance.exposed?.showDrawer) return console.error('instance.exposed.showDrawer is not defined', instance);
      return instance.exposed.showDrawer(params, extendParams)
    }

    if (!importComponentFunc) console.error('请先调用 installRankDrawer 注册组件加载方法')
    
    firstOpenPageFlag = getPageFlag()
    await importComponentFunc()

    // 将当前showDrawer调用加入待执行队列
    showDrawerQueue.push(() => {
      if (!instance.exposed?.showDrawer) return console.error('instance.exposed.showDrawer is not defined', instance);
      instance.exposed.showDrawer(params, extendParams)
    })
  },
  hideDrawer() {
    if (instance) {
      instance.hideDrawer()
    }
  },
}

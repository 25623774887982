// 预搜索页
export const preSearchPageFromBuriedPointKeys = [
  'PageNew',
  'PageHome',
  'PageTrend',
  'PageCategory',
  'PageRealClass',
  'PageGoodsDetail',
  'PageSearchResult',
  'PageSearchNoResult',
  'PageAdvertising',
  'PagePicked'
]

// new频道映射【旧->新】
const newBuriedPointKeyMapping = [
  ['page_new', 'PageNew'],
  ['page_activity_factory', 'PageNew']
]

// 列表映射【旧->新】
const listBuriedPointKeyMapping = [
  ['page_real_class', 'PageRealClass'],
  ['page_daily_new', 'PageDailyNew'],
  ['page_goods_group', 'PageGoodsGroup'],
  ['page_select_class', 'PageSelectClass'],
  ['page_virtual_class', 'PageVirtualClass'],
  ['page_picked', 'PagePicked'],
]
/*
	自有埋点page_name与产品要求的pageName规范不同，这里需要做一份映射
	部分老的page_name比较多所以抽出来方便维护
	【旧->新】
*/
export const buriedPointKeyMapping = new Map([
  ['page_home', 'PageHome'], // 首页
  ...newBuriedPointKeyMapping, // new频道
  ...listBuriedPointKeyMapping, // 列表
  ['page_category', 'PageCategory'], // 分类
  ['page_goods_detail', 'PageGoodsDetail'], // 商详
  ['page_top_trend', 'PageTrend'], // 趋势频道页
  ['page_trend_landing', 'PageTrendlanding'], // 趋势词落地页
  ['page_advertising', 'PageAdvertising'], // 站外营销页
  ['page_search', 'PageSearchResult'], // 搜索结果
])

// 进入预搜页的src_identifier额外处理
export const handleSrcIdentifier = srcIdentifier => {
  const keyArr = ['on', 'cn', 'hz', 'ps', 'jc']
  let tempSrcIdentifierObj = {}
  keyArr.forEach(keyVal => {
    tempSrcIdentifierObj[`src_identifier_${keyVal}`] = ''
  })

  if (srcIdentifier) {
    let idx = 0
    srcIdentifier.split('`').forEach(item => {
      let key = item.split('=')[0]
      if (keyArr.includes(key)) {
        idx++
        tempSrcIdentifierObj[`src_identifier_${key}`] = item.split('=')[1]
      }
    })
    if (idx > 1) {
      gbCommonInfo.src_identifier_pre_search = tempSrcIdentifierObj  // 全局记录一个src_identifier_pre_search，下游服务需要，因为后面预搜页去搜索页src_identifier这个字段会被修改
    }
  } else {
    // url上没有src_identifier的时候，src_identifier_pre_search里面对应的字段传空
    gbCommonInfo.src_identifier_pre_search = tempSrcIdentifierObj
  }
}

<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckoutNum',
  props: {
    num: {
      type: Number,
      default: 0
    }
  },
  render({ $props: props }) {
    const { num } = props
    return num ? (
      <span class="product-card__checkout-num">
        {num > 99 ? `99+` : num}
      </span>
    ) : null
  },
})
</script>

<style lang="less">
.product-card__checkout-num {
  position: absolute;
  right: 2px;
  top: 1px;
  transform: translate(30%, -60%);

  background: #fe3b30;
  border-color: #fff;
  padding: 1px 2px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 2000px;
  color: #fff;
  min-width: 14px;
  font-size: 10px;
  line-height: 10px;
}
</style>

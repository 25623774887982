/**
 * @file 商品上的角标控制器
 */

import ItemLocateLabels from './ItemLocateLabels.vue'
import ProductCardImgLeftTop from './ProductCardImgLeftTop.vue'
import ProductCardImgRightTop from './ProductCardImgRightTop.vue'
import { getLocateLabelsAna } from '../../analysis/locateLabelsAna'
import { isActivityPage } from '../../js/utils'

/**
 * 
 * @param {Function} h 不能删除，vue编译时会用到
 * @param {*} param1 
 * @returns 
 */
export const controlItemLabels = (h, {
  goodsInfo,
  locateLabels,
  multiColumn,
  isFlashSale,
  config,
  pretreatInfo,
  // language,
  constantData,
  curData,
  finalPriceAndDiscountInfo,
  changeMarkFocus,
  promoLabelSrc,
  selectStatus,
  showSelect,
  selectPosition,
  // showHotTag,
  $slots,
  handleFocusCheckbox,
  beltLabel,
}) => {
  // 专题页、闪购页需展示品牌、系列、大促角标的旧逻辑
  const needShowOldLabel = isActivityPage() || isFlashSale
  const locateLabelSet = new Set(Object.keys(locateLabels || {}).filter(key => Object.keys(locateLabels[key] || {}).length))

  // 左上角折扣角标
  const finalDiscount = finalPriceAndDiscountInfo?.finalDiscount || {}
  const priceStateCollection = finalPriceAndDiscountInfo?.priceStateCollection || {}
  const showDiscountLabel = config.showDiscountInMainImg && finalDiscount?.show && !priceStateCollection.hideDiscountLabel

  // @tips 收藏列表商品项有个checkbox
  const topRightProps = locateLabelSet.has('UPPER_RIGHT_LABEL') ? {
    promoLabelSrc: '',
  } : {
    promoLabelSrc,
  }
  const hideMainImgItemLocateLabels = config.hideMainImgItemLocateLabels

  const locateLabelsAna = getLocateLabelsAna(locateLabels, locateLabelSet, goodsInfo?.bffGoodsInfo)

  return {
    locateLabelsAna,
    ItemLocateLabels: () => !hideMainImgItemLocateLabels && locateLabelSet.size 
      ? (
        <ItemLocateLabels
          v-slots={{
            UPPER_LEFT_LABEL: (scope) => $slots.UPPER_LEFT_LABEL?.(scope),
            UPPER_RIGHT_LABEL: (scope) => $slots.UPPER_RIGHT_LABEL?.(scope),
            LOWER_LEFT_LABEL: (scope) => $slots.LOWER_LEFT_LABEL?.(scope),
            LOWER_RIGHT_LABEL: (scope) => $slots.LOWER_RIGHT_LABEL?.(scope),
          }}
          is-flash-sale={isFlashSale}
          belt-label={beltLabel}
          locate-labels={locateLabels}
          multi-column={multiColumn}
          lazy-img={constantData.LAZY_IMG_PIXEL}
        />
      ) : null,
    
    ProductCardImgLeftTop: () => <ProductCardImgLeftTop
      select-status={selectStatus}
      show-select={showSelect}
      select-position={selectPosition}
      show-discount-label={showDiscountLabel}
      onFocusCheckbox={handleFocusCheckbox}
    />,

    ProductCardImgLeftBottom: () => null, 
    
    ProductCardImgRightTop: () => <ProductCardImgRightTop
      pretreat-info={pretreatInfo}
      cur-data={curData}
      config={config}
      constant-data={constantData}
      select-status={selectStatus}
      show-select={showSelect}
      select-position={selectPosition}
      need-show-old-label={needShowOldLabel}
      {...topRightProps}
      onOnFocus={() => changeMarkFocus(true)}
      onOnBlur={() => changeMarkFocus(false)}
      onFocusCheckbox={handleFocusCheckbox}
    />
  }
}

<script lang="jsx">
import { defineComponent } from 'vue'
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import ProductCardimgCarousel from './ProductCardimgCarousel.vue'
import { columnToCutImageSize } from '../../js/constant'
import { reportMetricCount } from '../../js/utils'

export default defineComponent({
  name: 'ProductCardImg',
  inject: {
    isProductPage: {
      from: 'isProductPage',
      default: false,
    },
    getIsSupportCropImage: {
      from: 'getIsSupportCropImage',
      default: () => false,
    },
    constantData: {
      from: 'constantData',
      default: () => ({}),
    },
    config: {
      from: 'config',
      default: () => ({}),
    },
    item: {
      from: 'item',
      default: () => ({}),
    },
  },
  components: {
    SwiperSlide,
    SwiperSlideItem,
    CropImageContainer,
  },
  props: {
    mainImg: {
      type: String,
      default: '',
    },
    column: {
      type: [String, Number],
      default: 2
    },
    lcp: {
      type: Boolean,
    },
    index: {
      type: Number,
      default: 0,
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: '',
    },
    autoCropImage: {
      type: Boolean,
      default: false
    },
    reportMetrics: {
      type: Object,
      default: () => ({})
    },
    compSrc: {
      type: String,
      default: ''
    },
    compImgDesignWidth: {
      type: Number,
      default: 0
    }
  },
  render({ $props: props, $attrs: listeners }) {
    const { lcp, mainImg, index, column, autoCropImage, cropRate, reportMetrics, compSrc, compImgDesignWidth } = props
    const { constantData, isProductPage = false, getIsSupportCropImage, item, config } = this || {}
    const isSupportCropImage = getIsSupportCropImage && getIsSupportCropImage()
    const { LAZY_IMG_SOLID_COLOR, GB_cssRight } = constantData || {}

    // 首屏（转场、ssr）前6张，直接加载，不使用懒加载
    let isFirstPageProducts = index < 6 && isProductPage
    
    const isUseOriginUrl = lcp || isFirstPageProducts 
    const imgDesignWidth = Boolean(compImgDesignWidth) ? compImgDesignWidth : (columnToCutImageSize[`${column}`] || 0)
    const goodsName = item?.goods_name
    const imgThumb = item?._imgThumb
    const imgNotFsp = item?._imgNotFsp

    const className = []
    if (isUseOriginUrl) {
      className.push('product-card--bg-gray')
    }

    // 上报图片加载监控指标
    const reportImgLoadMetric = (imgLoadType, e) => {
      const isServer = typeof window === 'undefined'
      const pageName = config?.commonParams?.pageName
      // 图片加载成功次数指标上报
      if (reportMetrics.img && !isServer && pageName) {
        const metricInfo = {
          status: null,
        }
        const loadImgSucc = imgLoadType === 'succ'
        const loadImgFail = imgLoadType === 'fail'
        if (loadImgSucc) {
          Object.assign(metricInfo, { status: '1' })
        } else if (loadImgFail) {
          Object.assign(metricInfo, { status: '0' })
        }

        reportMetricCount({
          metricName: 'image_request_total',
          tags: { page: pageName, status: metricInfo.status },
          message: `Number of img loaded ${imgLoadType}`,
          params: {
            imgSrc: e.target?.src || '',
          }
        })
      }
    }

    const handleImgLoad = (imgLoadType, e) => {
      reportImgLoadMetric(imgLoadType, e)
      listeners?.['onImgLoadFinish']?.(imgLoadType, e)
    }

    // 单图

    const singleImage = () => {
      return (
        <CropImageContainer
          class={className}
          ignoreMask
          isSupportCropImage={isSupportCropImage}
          imgSrc={mainImg}
          fixedRatio={cropRate ? cropRate : (autoCropImage ? '' : '3-4')}
          transformImgProps={{
            imgClassName: `fsp-element product-card__main-img ${compSrc ? 'fsp__' + compSrc : ''}`,
            loadImg: LAZY_IMG_SOLID_COLOR,
            isFirstPage: isUseOriginUrl,
            imgDesignWidth,
            alt: goodsName,
            fetchpriority: 'high',
          }}
          imgThumb={imgThumb}
          notFsp={imgNotFsp}
          onImgLoadSucc={(e) => handleImgLoad('succ', e)}
          onImgLoadFail={(e) => handleImgLoad('fail', e)}
        />
      )
    }

    // 多图轮播： 主图 + 细节图
    const multiImageCarousel = () => {
      return (
        <ProductCardimgCarousel
          mainImg={mainImg}
          className={className}
          cropRate={cropRate}
          compSrc={compSrc}
          isUseOriginUrl={isUseOriginUrl}
          imgDesignWidth={imgDesignWidth}
          autoCropImage={autoCropImage}
          onImgLoadSucc={(e) => handleImgLoad('succ', e)}
          onImgLoadFail={(e) => handleImgLoad('fail', e)}
        ></ProductCardimgCarousel>
      ) 
    }

    return config.showMainImgCarousel ? multiImageCarousel() : singleImage()
  },
})
</script>

<style lang="less" scoped>
/* stylelint-disable-next-line */
.product-card--bg-gray {
  background-color: @sui_color_gray_weak1a;
}
.product-card__main-img-carousel {
  width: 100%;
  :deep(.slide-swiper-wrapper) {
    &>div {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}
</style>

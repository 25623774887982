import { transformImg, preloadImgs, isEmpty } from '@shein/common-function'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'

const isHitApollo = () => {
  // Apollo 已经推全
  return true

  // if (typeof window === 'undefined') {
  //   return isSupportCropImage
  // }

  // const { RESOURCE_SDK } = gbCommonInfo
  // if  (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
  //   return true
  // }

  // return false
}

// 商详实际要展示的主图src
export const getRealShowImg = ({ item, lazyLoadSrc }) => {
  // 展示营销图时，用原图
  const goodsOriginImage = item?.goodsOriginImage
  if (goodsOriginImage) {
    return { 
      imgSrc: !isHitApollo() ? goodsOriginImage : goodsOriginImage?.replace(/(_square)|(_squfix)/ig, ''), 
      showMarketingImg: true 
    }
  }

  // 裁剪图
  const isCut = checkIsCut(lazyLoadSrc)
  if (isCut) return { imgSrc: lazyLoadSrc } 

  // 主图
  return { imgSrc: getMainImg(item) }
}

export const getMainImg = (item) => {
  const firstSpuImg = (item.spu_image || [])[0]?.medium_image || ''

  const img = firstSpuImg ? firstSpuImg : item.goods_img

  return transformImg({ img })
}
export const getSecondImg = (item) => {
  const secondSpuImg = (item.spu_image || [])[1]?.medium_image || ''
  const secondDetailImage = secondSpuImg ? { 'origin_image': secondSpuImg } : ((item.detail_image || [])[0] || {})

  return transformSecondDetailImage(secondDetailImage?.origin_image || secondDetailImage || '', 336)
}

export const addProductDetailBfCache = (item, lazyLoadSrc = '') => {
  try {

    // 是否裁切过
    const isCut = checkIsCut(lazyLoadSrc)
    // 二图取值及尺寸处理
    const secondDetailImageUrl = getSecondImg(item)
    const data = {
      goods_img: getRealShowImg({ item, lazyLoadSrc }).imgSrc,
      // goods_lazy_img: isCut ? lazyLoadSrc : '',
      // 传给详情页的详细图, 暂时只传二图 (主图为 goods_img), 其他 3/4/5... 图不传, 详情页自己会去获取
      // 传二图的目的是让详情页的首页快速展示两张图
      detail_image: [{
        origin_image: secondDetailImageUrl,
      }],
      screen_data: {
        mallCode: item.mall_code,
        goods_name: item.goods_name,
        salePrice: item.salePrice,
        retailPrice: item.retailPrice,
        unit_discount: item.unit_discount,
      }
    }

    // 预取缓存取值
    const curGoodsFSInfo = window.__detail_fs_info_cache__.get(item.goods_id)
    if(curGoodsFSInfo && !isEmpty(curGoodsFSInfo)) {
      if (curGoodsFSInfo.isCccSkcPrepose) {
        data.screen_data.isCccSkcPrepose = true
      }

      if(curGoodsFSInfo.main_sale_attr_show_mode) { 
        data.screen_data.main_sale_attr_show_mode = curGoodsFSInfo.main_sale_attr_show_mode
      }

      const relatedColor = curGoodsFSInfo.relatedColor
      if (relatedColor?.length) {
        data.screen_data.relatedColor = relatedColor
      }

      const skc_sale_attr = curGoodsFSInfo.skc_sale_attr
      if (skc_sale_attr?.length) {
        data.screen_data.skc_sale_attr = skc_sale_attr
      }
    } else {
      data.screen_data.noPrefetch = true
    }
    
   

    // // 榜单
    // if (rankingList) {
    //   data.screen_data.rankingList = rankingList
    // }
    // // 到手价
    // if (item.estimatedPriceInfo) {
    //   data.screen_data.estimatedPriceInfo = item.estimatedPriceInfo
    // }
    const sellingPointUniversalLabels = item.pretreatInfo?.mobileVerticalView?.sellingPointUniversalLabels || []
    const { starComment } = sellingPointUniversalLabels.find(label => label.materialValueKey === 'number_of_comments') || {}
    // 星级评论
    if (starComment) {
      data.screen_data.starComment = starComment
    }
    // // 历史低价
    // if (historicalPrice) {
    //   data.screen_data.historicalPrice = historicalPrice
    // }
    // // 促销信息
    // if (item.promotionInfo) {
    //   data.screen_data.promotionInfo = item.promotionInfo
    // }
    // // 付费会员
    if (item.sheinClubPromotionInfo) {
      data.screen_data.sheinClubPromotionInfo = item.sheinClubPromotionInfo
    }

    // 有二图则提前预加载二图 (GIF图不预加载)
    if (secondDetailImageUrl && !checkIsGif(secondDetailImageUrl)) {
      preloadImgs({ imgs: [secondDetailImageUrl] })
    }
    sessionStorage.setItem('productDetailBfCache', JSON.stringify(data))
  } catch (error) {
    console.log(error)
  }
}

/**
 * 腰带与价格之间的控制逻辑
 * @param {Object} config abt相关的配置
 * @param {Object} param1 
 * @returns {{
 *  beltLabel: false | Object;
 *  showBestDeal: boolean;
 *  followLabelVisible: boolean;
 *  isPromoPrice: boolean;
 * }}
 */
export const controlBeltPrice = (config, {
  finalDiscount,
  isSoldOut = false,
  pretreatInfo,
  viewsKey = 'mobileVerticalView',
}) => {
  const { showBeltLabel } = config || {}
  const beltLabel = showBeltLabel && !isSoldOut && pretreatInfo?.[viewsKey]?.beltLabel // ccc配置的腰带
  
  if (beltLabel) {
    beltLabel.savedPriceText = finalDiscount?.discountPrice || ''
  }
  
  const showBestDeal = pretreatInfo.showBestDeal // 保留款腰带
  let followLabelVisible = pretreatInfo.followLabelVisible && !isSoldOut // 跟价款腰带
  let isPromoPrice = false // 价格是否展示促销状态

  if (beltLabel || showBestDeal) {
    // 保留款腰带>CCC配置的促销腰带>跟价款腰带
    followLabelVisible = false
  }
  if (followLabelVisible || showBestDeal) {
    isPromoPrice = true
  }

  return {
    beltLabel: beltLabel || {},
    showBestDeal,
    followLabelVisible,
    isPromoPrice,
  }
}

/**
 * 传入价格字符串,返回价格的整数(numberBeforeDot)、小数部分(numberAfterDot)、前后的币种符号(currencySymbolPrefix、currencySymbolSuffix)
 * @param {*} originString 
 * @returns 
 */
export const handlePriceHtml = (originString, amountString) => {
  if (!originString) return { currencySymbolPrefix: '', numberBeforeDot: '', numberAfterDot: '', currencySymbolSuffix: '' }
  
  // 货币符号在前
  const currencySymbolPrefix = originString?.match(/(^\D+)/)?.[0] || ''
  // 货币符号在后
  const currencySymbolSuffix = originString?.match(/(\D+)$/)?.[0] || ''
  // 去除货币符号的价格
  const priceWithoutSymbol =
    originString
      ?.replace(currencySymbolPrefix, '')
      ?.replace(currencySymbolSuffix, '') || ''
  // 价格是否有小数
  // const isDecimal = !!priceWithoutSymbol?.includes('.')
  // 由于","不仅表达小数点,还表达千分位,所以需要加入 amount 入参来判断是否有小数
  const isDecimal = !!amountString?.includes('.')
  // 价格小数部分
  const numberAfterDot = isDecimal ? priceWithoutSymbol.match(/\D\d+$/)?.[0] || '' : ''
  // 价格整数部分
  const numberBeforeDot = priceWithoutSymbol.replace(numberAfterDot, '')

  return { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix, originString }
}

/** 二图处理 */
const transformSecondDetailImage = (url, cutSize) => {
  let transfromUrl = url || ''

  if (!transfromUrl || typeof transfromUrl !== 'string') return ''
  
  // 如果没有携带尺寸信息则直接补充
  if (transfromUrl && transfromUrl?.indexOf('_thumbnail_') === -1) {
    return transformImg({ img: transfromUrl?.replace('.jpg', `_thumbnail_${cutSize}x.jpg`) })
  }

  // 转webp
  if (transfromUrl) {
    transfromUrl = transformImg({ img: url })
  }

  // 二图裁切
  return transfromUrl?.replace(/(\d+)x(\d*)(\.)(webp|png|jpg)$/, (match) => {
    const [, suffix] = match.split('.')
    return `${cutSize}x.${suffix}`
  })
}

/** 判断是否为gif图 */
const checkIsGif = (imgUrl = '') => {
  return /\b(\.gif\b)/.test(imgUrl)
}

/** 判断是否lazy (仅405或336尺寸的裁切图可直接运用于商详,其他太小的尺寸传到商详会比较模糊) */
const checkIsCut = (url) => {
  if (!url) return false
  return url.indexOf('_thumbnail_405') !== -1 || url.indexOf('_thumbnail_336') !== -1
}

/**
 * 是否是专题页
 * @returns {boolean}
 */
export const isActivityPage = () => {
  return gbCommonInfo?.tabActive === 'activity'
}

/**
 * 适用于 服务标签区域 分布式筛选
 * 计算一个行内的dom宽度，如果超过最大宽度则不显示
 * @param {*} width 最大宽度
 * @param {*} domList 需要计算宽度的dom列表
 * @returns 返回可以放进去的dom的index
 */
export const checkSlicingDomList = (width, domList = []) => {
  if (!domList.length) return []
  
  let widthCumulativeValue = width

  const marginRightString = window.getComputedStyle(domList[0])?.marginRight ?? ''
  const domMarginRight = Math.ceil(marginRightString.replace('px', ''))

  const showIndexList = []

  const domArray = [...domList]
  for(let i = 0; i < domArray.length; i++) {
    const element = domArray[i]
    const elWidth = element.offsetWidth + domMarginRight

    const surplus =  widthCumulativeValue - elWidth

    // 当还有宽度时则插入
    // 或者
    // 最后一个元素去掉margin能够完全显示
    if (
      surplus >= 0 || 
      (surplus <= 0 && surplus >= -domMarginRight)
    ) {
      showIndexList.push(i)
      widthCumulativeValue -= elWidth
    } else {
      break
    }
  }

  return {
    showIndexList,
    remainingWidth: widthCumulativeValue
  }
}

/**
 * 累加计数监控指标上报
 * @param {string} metricName 指标名称
 * @param {object} tags 指标维度
 * @param {string} message 日志信息 
 */

export const reportMetricCount = ({ metricName, tags, message, params = {} }) => {

  SIMetric.metricCount({
    metric_name: metricName,
    tags,
    message,
    params,
  })
}

// 历史最低价
export const hitHistoricalPrice = ({ salePrice, retailPrice, historicalPrice }) => {
  const price = Number(salePrice?.usdAmount || retailPrice.usdAmount)
  
  let data = null
  if (historicalPrice?.length && historicalPrice[0]?.appTraceInfo) {
    data = historicalPrice[0]
  } else {
    data = (historicalPrice || []).find(item => {
      const { high = {}, low = {}, adjust = {} } = item.priceData || {}
      const adjustNum = Number(adjust.tspVal)
      if (adjustNum <= 1) {
        return false
      }

      if (price < Number(high.tspVal) && price <= Number(low.tspVal)) {
        return true
      }
      return false
    })
  }

  return data ? {
    show: true,
    ...data,
  } : {
    show: false,
  }
}

// 计算字符长度
export const exportgetByteLength = (text) => {
  if (TextEncoder) {
    const encoder = new TextEncoder()
    const encodedText = encoder?.encode?.(text)
    return encodedText?.length
  } else {
    let byteLength = 0
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i)
      if (charCode <= 0x7F) {
        byteLength += 1
      } else if (charCode <= 0x7FF) {
        byteLength += 2
      } else if (charCode <= 0xFFFF) {
        byteLength += 3
      } else {
        byteLength += 4
      }
    }
    return byteLength
  }
}



